@import '@/common/styles/index';

.root {
  position: fixed;
  left: 0;
  top: 0;

  width: $sidebarWidth;
  height: 100%;
}

.children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  overflow: auto;
}
