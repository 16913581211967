@import '@/common/styles/index';

.root {
  margin-left: $sidebarWidth;
  padding: 24px;
  transition: margin 200ms ease-in-out;
}

.collapsed {
  margin-left: $sidebarCollapsedWidth;
}
