.root,
.body {
  position: relative;
}

.footer {
  margin-top: 24px;
  margin-bottom: 0;
}

.formItem {
  margin-bottom: 10px;
}
