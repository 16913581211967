.root {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 1.5rem;
  flex-direction: column;
}

.vertical {
  justify-content: center;
}
